<template>
  <div id="app">
    <el-container>
      <el-header v-if="$route.name === 'backstage'"
        ><HeaderBackstage
      /></el-header>
      <HeaderFrontstage v-else-if="$route.name !== 'login'" />
      <el-main><router-view /></el-main>
      <el-footer v-if="$route.name !== 'login'" class="el-footer">
        <p>
          © 2020 Alpha Biochemistry All rights reserved.
          <span>v0.3.20</span>
          <router-link
            :to="{ name: 'login' }"
            style="margin: auto; font-size: 12px; color: gray;margin-left: 10px;"
            >console</router-link
          >
        </p>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import HeaderBackstage from './components/Top-Header'
import HeaderFrontstage from './components/Header-front'
export default {
  name: 'app',
  components: {
    HeaderBackstage,
    HeaderFrontstage
  }
}
</script>

<style>
.header-frontstage {
  height: 80px !important;
}
</style>
