<template>
  <div class="home">
    <div class="col-center-wrap">
      <div>
        <div class="col-wrap">
          <h2>ALPHA BIOCHEMISTRY</h2>
          <p>
            Find the product you are looking for...
          </p>
          <div style="display:flex;">
            <button
              @click="$router.push({ name: 'products' })"
              class="btn-main-bg mt-2"
            >
              SEARCH
            </button>
          </div>
        </div>
        <div class="col-wrap contact">
          <div>
            <h2>Contact us</h2>
            <p>
              Feel free to contact us by telephone or email and we will be sure
              to get back to you as soon as possible.
            </p>
          </div>
          <div>
            <img src="../assets/undraw_message_sent.svg" alt="" />
          </div>
        </div>
        <div class="form">
          <div>
            <div>
              <h3>MAIN OFFICE</h3>
              <div class="col-title">
                TAOYUAN 長庚大學-創新育成中心 鴻洺科技有限公司
              </div>
              <div class="col-content">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14460.0766440864!2d121.3874884!3d25.0334238!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3a9e9e1246db8beb!2z6bS75rS656eR5oqA5pyJ6ZmQ5YWs5Y-4!5e0!3m2!1sen!2stw!4v1610892775857!5m2!1sen!2stw"
                  width="100%"
                  height="300"
                  frameborder="0"
                  style="border:0;margin-bottom:20px"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
              <div class="col-content">
                <div><i class="el-icon-phone" /></div>
                <div><a href="tel:03-211-8079">03-211-8079</a></div>
              </div>
              <div class="col-content">
                <div><i class="el-icon-printer" /></div>
                <div><a href="fax:03-211-8556">03-211-8556</a></div>
              </div>
              <div class="col-content">
                <div><i class="el-icon-message" /></div>
                <div>
                  <a href="mailto:info@bio-cando.com.tw"
                    >info@bio-cando.com.tw</a
                  >
                </div>
              </div>
              <div class="col-content">
                <div><i class="el-icon-location-information" /></div>
                <div>
                  <a
                    href="https://goo.gl/maps/WU6VScFieAfPnfcL9"
                    target="_blank"
                    >No. 259, Wenhua 1st Road, Guishan District, Taoyuan City,
                    333323</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3>SUPPORTIVE OFFICES</h3>
              <div class="col-title">TAIPEI</div>
              <div class="col-content">
                <div><i class="el-icon-phone" /></div>
                <div><a href="tel:02-26018990">02-26018990</a></div>
              </div>
              <div class="col-title">TAICHUNG</div>
              <div class="col-content">
                <div><i class="el-icon-phone" /></div>
                <div><a href="tel:04-2203-2468">04-2203-2468</a></div>
              </div>
              <div class="col-title">KAOHSIUNG</div>
              <div class="col-content">
                <div><i class="el-icon-phone" /></div>
                <div><a href="tel:07-3136620">07-3136620</a></div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  components: {},
  methods: {
    goto(name) {
      this.$router.push({ name })
    }
  }
}
</script>
<style lang="scss" scope>
@import '../element-variables.scss';

.home {
  padding: 20px 0;
}

// @keyframes hookani {
//   0%   {left:0; top:0;}
//   50%  {left:160px; top:0;}
//   100% {left:0; top:0;}
// }

// .ys-hook{
//   width: 100px;
//   position: relative;
//   animation: hookani 5s infinite;
//   animation-timing-function: ease-in-out;
// }
.col-wrap {
  padding: 40px;
  h2 {
    font-size: 56px;
    font-weight: 800;
    margin: 40px 20px 20px;
    letter-spacing: 1px;
    font-weight: 900;
  }
  p {
    margin: 0 20px 10px;
    font-size: 18px;
    line-height: 1.6;
  }
  @media (max-width: 900px) {
    padding: 10px 10px 30px;
    h2 {
      font-size: 24px;
      margin: 20px;
    }
  }
}
.contact {
  background: linear-gradient(90deg, $purple5 0%, $purple3 40%, $purple1 100%);
  color: #fff;
  display: flex;
  padding-bottom: 100px;
  > div {
    flex: 1;
    &:last-child {
      text-align: right;
    }
  }
  img {
    margin-right: 20px;
    width: 500px;
  }
  @media (max-width: 900px) {
    display: block;
    > div:last-child {
      text-align: center;
    }
    img {
      width: 90%;
      margin-right: 0;
    }
  }
}
.form {
  display: flex;
  padding: 0 60px;
  position: relative;
  top: -60px;
  > div {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 24px rgba(18, 29, 51, 0.08);
    &:first-child {
      flex: 1;
      margin-right: 20px;
    }
    &:last-child {
      width: 260px;
    }
  }
  h3 {
    color: $purple5;
    margin-top: 0;
    margin-bottom: 0;
    border-bottom: solid 1px $gray2;
    padding-bottom: 10px;
    font-size: 20px;
  }
  .col-title {
    line-height: 1.4;
    font-size: 16px;
    font-weight: 800;
    padding: 20px 0;
  }
  .col-content {
    cursor: pointer;
    font-size: 14px;
    padding: 0 0 10px;
    display: flex;
    div:first-child {
      display: inline-flex;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50%;
      background: $purple4;
      color: #fff;
      i {
        margin: auto;
      }
    }
    div:last-child {
      flex: 1;
      margin: auto;
      color: $purple4;
      span,
      a {
        color: $purple4;
        text-decoration: none;
        line-height: 30px;
        border-bottom: solid 1px $purple4;
      }
    }
  }
  @media (max-width: 900px) {
    display: block;
    padding: 0 20px;
    > div {
      width: calc(100% - 40px);
    }
    > div:last-child {
      width: calc(100% - 40px);
      position: relative;
      top: 20px;
    }
  }
}
</style>
