<template>
  <div class="ys-col-2-wrap">
    <div class="mb-hide" style="overflow: hidden;height: 100vh">
      <div class="col-center-wrap">
        <img src="@/assets/undraw_work_chat_erdt.svg" style="max-width: 40vw" />
      </div>
      <div></div>
    </div>
    <div class="col-center-wrap ys-bg-dark">
      <img
        src="@/assets/undraw_work_chat_erdt.svg"
        class="desk-hide"
        style="position: absolute;width: 50vw;left: 25vw;top: 5vh; z-index: 0;"
      />
      <div class="ys-card">
        <div class="ys-card-header">
          <span>Alpha Biochemistry </span>
          <span>Admin Login</span>
        </div>
        <div class="ys-card-main">
          <el-input class="mb-20" v-model="email" placeholder="email" />
          <el-input
            class="mb-20"
            v-model="password"
            placeholder="password"
            show-password
          />
          <el-button class="mb-20" type="primary" @click="login"
            >LOGIN</el-button
          >
          <el-button class="mb-20" type="primary" @click="gotoHome"
            >HOME</el-button
          >
          <div class="error" v-if="error">{{ error }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
require('firebase/auth')
export default {
  data() {
    return {
      email: '',
      password: '',
      error: ''
    }
  },
  methods: {
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((data) => {
          console.log(data)
          this.$store.commit('login')
          this.$router.push({
            name: 'backstage',
            params: { page: 'product_list' }
          })
        })
        .catch((error) => {
          console.log(error)
          this.error = 'error'
        })
    },
    gotoHome() {
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.col-center-wrap
  height: 100vh
.ys-card
  max-width: 80%
  position: relative
</style>
