<template>
  <div v-if="$store.state.admin.login">
    <Productlist v-if="$route.params.page === 'product_list'" />
    <AboutContent v-if="$route.params.page === 'about_content'" />
    <ContactContent v-if="$route.params.page === 'contact_content'" />
  </div>
</template>

<script>
import Productlist from '../components/product-list'
import AboutContent from '../components/about-content'
import ContactContent from '../components/contact-content'

export default {
  components: {
    Productlist,
    AboutContent,
    ContactContent
  }
}
</script>

<style lang="scss" scoped></style>
