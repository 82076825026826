<template>
  <div>
    <div class="col-center-wrap">
      <div style="width:90%">
        <div class="dp-flex">
          <div
            style="line-height: 40px; font-weight: bold; font-size: 20px;color: #303777"
          >
            Product List
          </div>
          <el-button class="ml-a" type="primary" @click="handleCreate()"
            >+ NEW</el-button
          >
        </div>
        <!-- <p style="color: gray">(請按左側箭頭展開商品詳細資訊)</p> -->
        <div class="mt-20">
          <el-table
            v-if="products && products.length > 0"
            v-loading="tableLoading"
            width="100%"
            :data="products"
            :default-sort="{ prop: 'time', order: 'descending' }"
            border
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" class="demo-table-expand">
                  <el-form-item label="VOLUME">
                    <div>{{ props.row.info }}</div>
                  </el-form-item>
                  <el-form-item>
                    <div
                      v-for="(image, index) in props.row.images"
                      :key="index"
                      class="mr-20 ys-image-card"
                    >
                      <div @click="deleteImage(image, props.row)">
                        <i class="el-icon-delete" />
                      </div>
                      <img :src="image.link" style="max-width: 300px;" />
                    </div>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="order"
              label="排序"
              sortable
              width="80" /> -->
            <el-table-column
              prop="time"
              label="created date"
              sortable
              width="140"
            >
              <template slot-scope="scope">
                {{ scope.row.time | moment('YYYY/MM/DD HH:mm') }}
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="NAME"
              sortable
              min-width="180"
            />
            <el-table-column prop="abc" label="ABC" sortable width="120" />
            <el-table-column prop="cas" label="CAS" sortable width="120" />
            <el-table-column prop="price" label="PRICE" sortable width="120" />
            <el-table-column label="edit/delete" width="180">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)"
                  >edit</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >delete</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-drawer
        title="編輯"
        v-loading="loading"
        :before-close="cancelForm"
        :visible.sync="dialog"
        direction="rtl"
        :with-header="false"
        custom-class="ys-drawer"
        ref="drawer"
      >
        <div class="ys-drawer__content">
          <h4>{{ isNew ? `新增` : `編輯` }}</h4>
          <el-form :model="form">
            <el-form-item label="NAME" :label-width="formLabelWidth">
              <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="ABC" :label-width="formLabelWidth">
              <el-input v-model="form.abc" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="CAS" :label-width="formLabelWidth">
              <el-input v-model="form.cas" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="PRICE" :label-width="formLabelWidth">
              <el-input v-model="form.price" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="VOLUME" :label-width="formLabelWidth">
              <el-input
                type="textarea"
                v-model="form.info"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <div style="font-size: 14px;color: #606266;line-height: 40px;">
            上傳圖片
          </div>
          <div class="ml-70" style="position: relative; top:-38px;">
            <el-upload
              class="upload-demo"
              ref="upload"
              action=""
              list-type="picture-card"
              :on-remove="handleUpdateRemove"
              :on-change="handleUpdateChange"
              :file-list="fileList"
              :auto-upload="false"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
          <div class="ys-drawer__footer dp-flex">
            <el-button class="ml-a" @click="cancelForm">取 消</el-button>
            <el-button
              type="primary"
              @click="handleClose()"
              :loading="loading"
              >{{ loading ? '提交中 ...' : '確定' }}</el-button
            >
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import firebaseConfig from '../../config.json'
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

const db = firebase.firestore()
require('firebase/auth')

export default {
  data() {
    return {
      isNew: true,
      products: [],
      productId: '',
      newproducts: null,
      // secrets: "",
      dialog: false,
      loading: false,
      tableLoading: false,
      form: {
        name: '',
        abc: '',
        cas: '',
        price: '',
        info: '',
        time: null,
        images: []
      },
      formLabelWidth: '70px',
      timer: null,
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: []
    }
  },
  firestore: {
    products: db.collection('products')
    // currentTodo: db.collection('todos').doc('1'),
  },
  // created() {
  //   // this unbinds any previously bound reference
  //   this.$bind('products', db.collection('products')).then(products => {
  //     this.products === products
  //     // todos are ready to be used
  //     // if it contained any reference to other document or collection, the
  //     // promise will wait for those references to be fetched as well

  //     // you can unbind a property anytime you want
  //     // this will be done automatically when the component is destroyed
  //     // this.$unbind('products')
  //   })
  // },
  mounted() {
    // storageRef.child('files/uid')
    // firebase.storage().ref(`images/products/${this.productId}`).listAll()
    // this.getSecrets();
  },
  methods: {
    // async getSecrets() {
    //   const token = await firebase.auth().currentUser.getIdToken();
    //   let config = {
    //     headers: {
    //       Authorization: `Bearer ${token}`
    //     }
    //   };

    //   this.secrets = await this.$axios.get(
    //     "http://localhost:3000/erik",
    //     config
    //   );
    //   this.secrets = this.secrets.data;
    // },
    createProduct() {
      this.loading = true
      this.form.order =
        this.products && this.products.length ? this.products.length + 1 : 0
      this.form.time = new Date().getTime()
      db.collection('products')
        .add(this.form)
        .then((docRef) => {
          this.productId = docRef.id
        })
        .then(() => {
          if (this.fileList.length > 0) {
            this.submitUpload()
          } else {
            this.loading = false
            this.dialog = false
          }
        })
        .catch((error) => {
          this.loading = false
          console.error('Error adding document: ', error)
        })
    },
    editProduct() {
      this.loading = true
      if (this.fileList.length > 0) {
        this.submitUpload()
      } else {
        db.collection('products')
          .doc(this.productId)
          .set(this.form)
          .then(() => {
            this.loading = false
            this.dialog = false
          })
          .catch((error) => {
            this.loading = false
            console.error('Error adding document: ', error)
          })
      }
    },
    cancelForm() {
      this.loading = false
      this.dialog = false
      this.productId = ''
      this.form = {
        name: '',
        abc: '',
        price: '',
        cas: '',
        info: '',
        images: []
      }
    },
    handleCreate() {
      this.isNew = true
      this.dialog = true
      this.fileList = []
    },
    handleEdit(index, row) {
      this.isNew = false
      this.form = { ...row }
      this.fileList = []
      this.productId = row.id
      this.dialog = true
    },
    handleClose() {
      if (this.isNew) {
        this.createProduct()
      } else {
        this.editProduct()
      }
    },
    handleUpdateChange(file, fileList) {
      this.fileList = fileList
    },
    handleUpdateRemove(file, fileList) {
      this.fileList = fileList
    },
    handleDelete(index, row) {
      db.collection('products')
        .doc(row.id)
        .delete()
        .then(function() {
          console.log('Document successfully deleted!')
        })
        .catch(function(error) {
          console.error('Error removing document: ', error)
        })
    },
    submitUpload() {
      let count = this.fileList.length
      this.fileList.forEach((file) => {
        let url = `images/products/${this.productId}/${file.name}`
        firebase
          .storage()
          .ref(url)
          .put(file.raw)
          .then(() => {
            firebase
              .storage()
              .ref()
              .child(url)
              .getDownloadURL()
              .then((newUrl) => {
                this.form.images.push({ name: file.name, link: newUrl })
                count--
                if (count === 0) {
                  db.collection('products')
                    .doc(this.productId)
                    .set(this.form)
                    .then(() => {
                      this.loading = false
                      this.dialog = false
                    })
                }
              })
          })
      })
    },
    deleteImage(image, row) {
      this.tableLoading = true
      let newform = { ...row }
      let imageIndex = newform.images.findIndex((x) => x.name === image.name)
      newform.images.splice(imageIndex, 1)
      db.collection('products')
        .doc(row.id)
        .set(newform)
        .then(() => {
          firebase
            .storage()
            .ref(`images/products/`)
            .child(`${row.id}/${image.name}`)
            .delete()
            .then(() => {
              this.tableLoading = false
            })
            .catch((error) => {
              this.tableLoading = false
              console.log('// Uh-oh, an error occurred!', error)
            })
        })
        .catch((error) => {
          this.tableLoading = false
          console.log('// Uh-oh, an error occurred!', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.col-center-wrap {
  padding: 20px;
}
</style>
