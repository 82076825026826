<template>
  <div class="header">
    <H1 class="logo" style="display:none">ALPHA BIOCHEMISTRY</H1>
    <router-link style="margin-right: auto" :to="{ name: 'home' }" class="logo">
      <img src="../assets/logo.jpg" alt="" />
    </router-link>
    <router-link
      :to="{ name: 'home' }"
      class="link"
      :class="{ active: $route.name === 'home' }"
      >HOME</router-link
    >
    <router-link
      :to="{ name: 'products' }"
      class="link"
      :class="{ active: $route.name === 'products' }"
      >PRODUCTS</router-link
    >
  </div>
</template>

<script>
export default {
  name: 'header-front',
  mounted() {},
  methods: {},
  data() {
    return {
      activeIndex: '1'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../element-variables.scss';

.header {
  display: flex;
  position: sticky;
  top: 20px;
  height: 80px;
  margin: 20px;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 20px;
  z-index: 3;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  border-color: rgba(223, 225, 229, 0);
  .link {
    margin: auto 20px auto 0;
    outline: none;
    text-decoration: none;
    color: $purple5;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 6px 0;
    cursor: pointer;
    border-bottom: 2px solid $gray2;
    transition: 0.4s ease;
    &.active {
      padding: 6px 0;
      border-bottom: 2px solid $purple5;
    }
    &:hover {
      padding: 12px 0;
      border-bottom: 2px solid $purple5;
    }
  }
  .logo {
    margin: auto;
    margin-left: 20px;
    img {
      width: 128px;
    }
  }
  @media (max-width: 768px) {
    top: 5px;
    margin: 5px;
    border-radius: 10px;
    padding: 20px 0;
    .link {
      margin: auto 15px auto 0;
      &:hover {
        padding: 6px 0;
        border-bottom: 2px solid $gray2;
      }
      &.active {
        padding: 6px 0;
        border-bottom: 2px solid $purple5;
      }
    }
    .logo {
      margin-left: 15px;
    }
  }
}
</style>
