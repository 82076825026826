<template>
  <div v-if="$store.state.admin.login">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1" @click="to('product_list')"
        >Product List</el-menu-item
      >
      <el-menu-item index="2" @click="to('about_content')"
        >About Us</el-menu-item
      >
      <el-menu-item index="3" @click="to('contact_content')"
        >Contact Us</el-menu-item
      >
      <el-menu-item index="4" @click="signOut()">Logout</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import firebase from 'firebase/app'
require('firebase/auth')

export default {
  name: 'top-header',
  mounted() {
    this.setupFirebase()
  },
  // watch: {
  //   $route (to, from) {
  //     console.log(to.name)
  //     console.log(from.name)

  //   }
  // },
  methods: {
    setupFirebase() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in.
          this.$store.commit('login')
        } else {
          // No user is signed in.
          this.$store.commit('logout')
        }
      })
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit('logout')
          this.$router.push({ name: 'login' })
        })
    },
    to(pathName) {
      this.$router.push({ name: 'backstage', params: { page: pathName } })
    },
    handleSelect(key) {
      console.log(key)
    }
  },
  data() {
    return {
      activeIndex: '1'
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  color: inherit;
}
</style>
