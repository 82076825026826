import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    admin: {
      login: false,
    }
  },
  mutations: {
    login (state) {
      state.admin.login = true
    },
    logout (state) {
      state.admin.login = false
    },
  },
  actions: {

  },
  strict: true
})

