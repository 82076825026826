<template>
  <div>
    <div class="col-center-wrap">
      <div style="width:90%">
        <div class="dp-flex">
          <div
            style="line-height: 40px; font-weight: bold; font-size: 20px;color: #303777"
          >
            Contact Us
          </div>
        </div>
        <div>
          <div style="color: gray" class="mt-10">
            last modified author: {{ contact.editor }}
          </div>
          <div style="color: gray" class="mt-10">
            last modified date:
            {{ contact.editTime | moment('YYYY/MM/DD HH:mm') }}
          </div>
        </div>
        <div class="mt-20">
          <el-form ref="form" :model="contact" label-width="100px">
            <el-form-item label="Address">
              <el-input
                v-model="contact.address"
                :disabled="!isContactEditable"
                placeholder="Address"
              ></el-input>
            </el-form-item>
            <el-form-item label="Phone">
              <el-input
                v-model="contact.phone"
                :disabled="!isContactEditable"
                placeholder="Phone"
              ></el-input>
            </el-form-item>
            <el-form-item label="Fax">
              <el-input
                v-model="contact.fax"
                :disabled="!isContactEditable"
                placeholder="Fax"
              ></el-input>
            </el-form-item>
            <el-form-item label="Email">
              <el-input
                v-model="contact.email"
                :disabled="!isContactEditable"
                placeholder="Email"
              ></el-input>
            </el-form-item>
            <el-form-item label="Content">
              <el-input
                v-model="contact.content"
                :disabled="!isContactEditable"
                placeholder="Content"
                type="textarea"
                :rows="6"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="dp-flex mt-20 mb-20">
          <el-button
            :disabled="isContactEditable"
            class="ml-a"
            type="primary"
            @click="isContactEditable = true"
            >開始編輯</el-button
          >
          <el-button
            :disabled="!isContactEditable"
            type="primary"
            @click="editContact()"
            >完成編輯</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseConfig from '../../config.json'
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

const db = firebase.firestore()
require('firebase/auth')

export default {
  data() {
    return {
      isContactEditable: false,
      contact: {}
    }
  },
  firestore: {
    contact: db.collection('homepage').doc('contact')
  },
  methods: {
    editContact() {
      this.loading = true
      let newContact = { ...this.contact }
      let author = firebase.auth().currentUser
      newContact.editor = author.email
      newContact.editTime = new Date().getTime()
      db.collection('homepage')
        .doc('contact')
        .set(newContact)
        .then(() => {
          this.loading = false
          this.isContactEditable = false
        })
        .catch((error) => {
          this.loading = false
          console.error('Error adding document: ', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.col-center-wrap {
  padding: 20px;
}
</style>
