<template>
  <div>
    <div class="col-center-wrap">
      <div style="width:90%">
        <div class="dp-flex">
          <div
            style="line-height: 40px; font-weight: bold; font-size: 20px;color: #303777"
          >
            About Us
          </div>
        </div>
        <div>
          <div style="color: gray" class="mt-10">
            last modified author: {{ about.editor }}
          </div>
          <div style="color: gray" class="mt-10">
            last modified date:
            {{ about.editTime | moment('YYYY/MM/DD HH:mm') }}
          </div>
        </div>
        <div class="mt-20">
          <el-form ref="form" :model="about" label-width="100px">
            <el-form-item label="Title">
              <el-input
                v-model="about.title"
                :disabled="!isAboutEditable"
                placeholder="Title"
              ></el-input>
            </el-form-item>
            <el-form-item label="Subtitle">
              <el-input
                v-model="about.subTitle"
                :disabled="!isAboutEditable"
                placeholder="Subtitle"
              ></el-input>
            </el-form-item>
            <el-form-item label="Content">
              <el-input
                v-model="about.content"
                :disabled="!isAboutEditable"
                placeholder="Content"
                type="textarea"
                :rows="6"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="dp-flex mt-20 mb-20">
          <el-button
            :disabled="isAboutEditable"
            class="ml-a"
            type="primary"
            @click="isAboutEditable = true"
            >開始編輯</el-button
          >
          <el-button
            :disabled="!isAboutEditable"
            type="primary"
            @click="editAbout()"
            >完成編輯</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseConfig from '../../config.json'
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

const db = firebase.firestore()
require('firebase/auth')

export default {
  data() {
    return {
      isAboutEditable: false,
      about: {}
    }
  },
  firestore: {
    about: db.collection('homepage').doc('about')
  },
  methods: {
    editAbout() {
      this.loading = true
      let newAbout = { ...this.about }
      let author = firebase.auth().currentUser
      newAbout.editor = author.email
      newAbout.editTime = new Date().getTime()
      db.collection('homepage')
        .doc('about')
        .set(newAbout)
        .then(() => {
          this.loading = false
          this.isAboutEditable = false
        })
        .catch((error) => {
          this.loading = false
          console.error('Error adding document: ', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.col-center-wrap {
  padding: 20px;
}
</style>
