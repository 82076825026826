<template>
  <div class="col-center-wrap">
    <div class="ys-container">
      <h2 class="md-hide">Products List</h2>
      <div class="search">
        <i class="el-icon-search"></i>
        <input
          v-model="searchkey"
          type="text"
          placeholder="search name, CAS, ABC, volume ..."
          @keyup.enter="search()"
        />
        <button @click="search()">SEARCH</button>
      </div>
      <p v-if="searchTarget">Find {{ searchResultNum }} results:</p>
      <div class="ys-list">
        <div
          v-for="(item, index) in newProducts"
          :key="index"
          v-show="show(item)"
        >
          <div class="card card2">
            <div class="card-col">
              <div class="md-hide col-title">NAME</div>
              <div>{{ item.name }}</div>
            </div>
            <div class="card-col">
              <div>
                <span class="col-title2">CAS</span>
                <span>{{ item.cas }}</span>
              </div>
              <div>
                <span class="col-title2">ABC</span>
                <span>{{ item.abc }}</span>
              </div>
              <div>
                <span class="col-title2">PRICE</span>
                <span>{{ item.price }}</span>
              </div>
              <div>
                <span class="col-title2">VOLUME </span>
                <span>{{ item.info }}</span>
              </div>
            </div>
            <div
              class="img-wrapper"
              style="cursor:pointer;"
              @click="previewImg(item.images)"
            >
              <img
                v-if="item.images[0] && item.images[0].link"
                class="img-sm"
                :src="item.images[0].link"
                loading="lazy"
                alt=""
              />
            </div>
            <!-- <button
              @click="activeBtn(index)"
              :class="{ active: item.btnStatus }"
            >
              <i class="el-icon-arrow-down"></i>
            </button> -->
          </div>
          <!-- <div class="card-detail" :class="{ active: item.btnStatus }">
            <div v-for="(image, imageIndex) in item.images" :key="imageIndex">
              <img class="img-sm" :src="image.link" alt="" />
            </div>
          </div> -->
        </div>
      </div>
      <button
        class="gotoTopBtn"
        :class="{ active: showGotoTopBtn }"
        @click="goTop()"
      >
        <i class="el-icon-caret-top" />
      </button>
    </div>
    <div class="previewWrapper" v-if="showImg">
      <div class="previewBack" @click="showImg = ''"></div>
      <div class="previewImgBack">
        <img :src="showImg" alt="" />
      </div>
      <button class="closeBtn" @click="showImg = ''">
        <i class="el-icon-close" />
      </button>
    </div>
  </div>
</template>

<script>
import firebaseConfig from '../../config.json'
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

const db = firebase.firestore()
require('firebase/auth')
// @ is an alias to /src

export default {
  name: 'home',
  components: {},
  data() {
    return {
      products: [],
      newProducts: [],
      activelist: [],
      searchkey: '',
      searchTarget: '',
      searchResultNum: 0,
      showGotoTopBtn: false,
      showImg: '',
      loading: true
    }
  },
  firestore: {
    products: db.collection('products').orderBy('name')
  },
  methods: {
    goto(name) {
      this.$router.push({ name })
    },
    goTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    activeBtn(index) {
      let item = this.newProducts[index]
      if (item.btnStatus) {
        item.btnStatus = false
      } else {
        item.btnStatus = true
      }
      this.$set(this.newProducts, index, item)
      console.log(this.newProducts[index])
    },
    search() {
      this.searchTarget = this.searchkey
      this.searchResultNum = 0
      this.newProducts.forEach((item) => {
        if (this.show(item)) {
          this.searchResultNum++
        }
      })
    },
    show(item) {
      if (this.searchTarget.trim() === '') {
        return true
      } else {
        let searchTarget = this.searchTarget.toLowerCase().trim()
        let name = item.name
          .toLowerCase()
          .trim()
          .indexOf(searchTarget)
        let cas = item.cas
          .toLowerCase()
          .trim()
          .indexOf(searchTarget)
        let abc = item.abc
          .toLowerCase()
          .trim()
          .indexOf(searchTarget)
        let info = item.info
          .toLowerCase()
          .trim()
          .indexOf(searchTarget)
        if (name > -1 || cas > -1 || abc > -1 || info > -1) {
          return true
        } else {
          return false
        }
      }
    },
    handleScroll() {
      if (window.scrollY > 50) {
        this.showGotoTopBtn = true
      } else this.showGotoTopBtn = false
    },
    previewImg(images) {
      if (images.length > 0) {
        this.showImg = images[0].link
      } else {
        this.showImg = ''
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.$nextTick(() => {
      this.loading = true
      db.collection('products')
        .get()
        .then(() => {
          this.newProducts = JSON.parse(JSON.stringify(this.products))
          this.newProducts.forEach((element) => {
            element.btnStatus = false
          })
          console.log(this.newProducts)
          this.loading = false
        })
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style lang="scss">
@import '../element-variables.scss';

.col-center-wrap {
  background: $gray1;
}

.md-hide {
  @media (max-width: 768px) {
    display: none;
  }
}

.search {
  flex: 1;
  display: flex;
  margin: 40px 0;
  padding-left: 14px;
  background: #fff;
  border-radius: 20px;
  height: 40px;
  box-shadow: 0px 4px 24px rgba(18, 29, 51, 0.08);
  transition: all 0.4s ease;
  i {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 24px;
    color: $purple5;
  }
  input {
    margin-left: 10px;
    margin-right: 10px;
    flex: 1;
    border: none;
    background: transparent;
    font-size: 16px;
  }
  button {
    margin-top: auto;
    margin-bottom: auto;
    height: 40px;
    width: 140px;
    border-radius: 20px;
    border: none;
    color: #fff;
    background: $purple5;
    font-size: 16px;
    font-weight: 800;
    transition: all 0.4s ease;
    @media (max-width: 768px) {
      display: none;
    }
  }
  button:hover {
    background: $purple6;
  }
  @media (max-width: 768px) {
    margin: 0 0 20px 0;
  }
}
.search:hover,
.search:active {
  height: 60px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  button {
    margin-right: 10px;
  }
}

p {
  margin: 40px 20px;
  font-weight: 800;
  @media (max-width: 768px) {
    margin: 20px;
  }
}

.ys-list {
  position: relative;
  > div {
    margin: 20px 0;
  }
  .card {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    border-radius: 20px;
    background: #fff;
    display: flex;
    overflow: hidden;
    z-index: 2;
    .card-col {
      flex: 1;
      padding: 20px;
      padding-right: 0;
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      .col-title,
      .col-title2 {
        font-size: 10px;
        line-height: 14px;
        font-weight: 800;
        min-width: 62px;
      }
      .col-title2 {
        display: inline-block;
      }
      &:first-child {
        min-width: 320px;
        @media (max-width: 900px) {
          min-width: 200px;
        }
      }
    }
    @media (max-width: 768px) {
      display: block;
      .card-col {
        padding: 16px 20px 0;
        &:first-child,
        &:nth-last-child(2) {
          padding-bottom: 16px;
        }
        &:first-child {
          border-bottom: solid 1px $gray2;
          font-weight: 800;
          font-size: 16px;
        }
      }
    }
    button {
      padding: 0;
      width: 60px;
      max-width: 60px;
      border: none;
      background: $purple5;
      color: #fff;
      flex: 0.56 0;
      cursor: pointer;
      transition: all 0.4s ease;
      &:hover,
      &:active {
        background: $purple6;
      }
      i {
        font-size: 22px;
        margin: 10px;
        transform: rotate(0deg);
        transition: transform 0.4s ease;
      }
      &.active {
        i {
          transform: rotate(180deg);
        }
      }
      @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .card2 {
    min-height: 120px;
  }
  .card-detail {
    position: relative;
    top: -20px;
    height: 0;
    padding: 5px 20px;
    overflow: hidden;
    background: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 4px 24px rgba(18, 29, 51, 0.08);
    transition: all 0.4s ease;
    > div {
      display: inline-block;
      margin: 10px;
      box-shadow: 0px 4px 24px rgba(18, 29, 51, 0.08);
    }
    &.active {
      height: 100%;
      padding: 60px 20px 20px;
    }
  }
}

.img-wrapper {
  display: flex;
  padding: 5px;
  margin: auto 20px;
  min-width: 210px;
  min-height: 80px;
  box-shadow: 0px 4px 24px rgba(18, 29, 51, 0.08);
  border-radius: 10px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.img-sm {
  margin: auto;
  max-width: 200px;
  max-height: 70px;
}

.gotoTopBtn {
  position: fixed;
  display: flex;
  min-width: 50px;
  min-height: 50px;
  bottom: 20px;
  right: -50px;
  z-index: 3;
  color: #fff;
  background: black;
  opacity: 0.4;
  border-radius: 50%;
  border: none;
  outline: none;
  transition: all 0.3s ease-in-out;
  i {
    font-size: 26px;
    margin: auto;
  }
  &:hover {
    opacity: 0.7;
  }
  &.active {
    right: 20px;
  }
}

.previewWrapper {
  z-index: 997;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  display: flex !important;
  .previewBack {
    position: fixed;
    z-index: 998;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: black;
  }
  .previewImgBack {
    position: fixed;
    z-index: 999;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    img {
      max-height: 80vh;
      max-width: 80vw;
    }
  }
  .closeBtn {
    position: fixed;
    display: flex;
    min-width: 50px;
    min-height: 50px;
    top: 20px;
    right: 20px;
    z-index: 999;
    color: $gray5;
    background: #fff;
    opacity: 0.5;
    border-radius: 50%;
    border: none;
    outline: none;
    transition: all 0.4s ease;
    i {
      font-size: 26px;
      margin: auto;
    }
    &:hover {
      opacity: 1;
    }
  }
}
</style>
