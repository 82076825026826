import Vue from 'vue'
import App from './App.vue'
import firebase from 'firebase/app'
import { firestorePlugin } from 'vuefire'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import firebaseConfig from '../config.json'
import './plugins/element.js'
require('firebase/auth')
Vue.config.productionTip = false
Vue.prototype.$axios = axios

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

Vue.use(firebase)
Vue.use(firestorePlugin)
Vue.use(require('vue-moment'))

firebase.auth().onAuthStateChanged(user => {
  console.log("user", user)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
